.game-card {
  /* background: url("/path-to-your-background-image.jpg") no-repeat center center; */
  background-size: cover;
  border-radius: 12px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
  transition: transform 0.3s;
}

.game-card:hover {
  transform: scale(1.02);
}

.card-header {
  color: #ffd700;
  font-family: "MedievalSharp", cursive;
  font-size: 1.25rem;
  text-align: center;
}

.card-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.mode-tag {
  font-size: 1rem;
  font-weight: bold;
  color: #ff7f50 !important;
}

.icon-glow {
  color: #ffd700;
  filter: drop-shadow(0px 0px 4px #ffbf00);
  font-size: 1.25rem;
}

.time-info {
  font-size: 1rem;
  color: #d3d3d3;
}

.play-button {
  font-size: 1.25rem;
  font-weight: bold;
  background-color: #8b008b !important;
  border: none;
  transition: background-color 0.3s;
}

.play-button:hover {
  background-color: #ff1493 !important;
}

.play-button:disabled {
  background-color: #a9a9a9 !important;
  color: #d3d3d3 !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

form,
body,
button {
  user-select: none; /* Disable text selection */
}

.scrollbar-gamified {
  scrollbar-width: thin; /* Thin scrollbar for Firefox */
}

/* WebKit-based browsers */
.scrollbar-gamified::-webkit-scrollbar {
  width: 12px; /* Increase scrollbar width for a more prominent look */
}

/* Gradient track */
.scrollbar-gamified::-webkit-scrollbar-track {
  background: linear-gradient(135deg, #ff7b00, #fcb900);
}

/* Thumb with gradient and shadow for a 3D effect */
.scrollbar-gamified::-webkit-scrollbar-thumb {
  background: linear-gradient(135deg, #12c2e9, #c471ed, #f64f59);
  border-radius: 6px; /* Rounded edges */
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.4); /* Inner shadow for depth */
}

/* Hover effect with brighter colors */
.scrollbar-gamified::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(135deg, #0fb8ad, #1fc8db, #2cb5e8);
}
